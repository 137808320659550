// src/App.tsx
import { Routes, Route, Navigate } from 'react-router-dom';
import { WalletContextProvider } from './components/WalletContextProvider';
import { LandingPage } from './components/LandingPage';
import { TokenScreener } from './components/tokenScreener';
import { PaymentGate } from './components/PaymentGate';
import { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { usePaymentStore } from './lib/payment';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import './index.css';
import { ErrorBoundary } from './components/ErrorBoundary';
import { TREASURY_WALLET } from './lib/constants';

function AppContent() {
  const { publicKey } = useWallet();
  const { hasAccess } = usePaymentStore();
  const [isPaymentGateOpen, setIsPaymentGateOpen] = useState(false);

  console.log('Wallet public key:', publicKey?.toBase58());
  console.log('Has access:', hasAccess);

  const isTreasuryWallet = publicKey?.toBase58() === TREASURY_WALLET;
  const hasPermission = hasAccess || isTreasuryWallet;

  const handleOpenPaymentGate = () => {
    if (!isTreasuryWallet) {
      setIsPaymentGateOpen(true);
    }
  };

  const handlePaymentSuccess = () => {
    setIsPaymentGateOpen(false);
  };

  const renderAccessGate = () => (
    <div className="min-h-screen bg-[#0F1114] text-gray-100 font-mono flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full p-8 backdrop-blur-sm bg-white/5 rounded-lg border border-white/10 text-center">
        <h2 className="text-2xl mb-4">Access Required</h2>
        <p className="text-gray-400 mb-8">
          Purchase access for <span className="text-[#14F195]">5 SOL</span>
        </p>

        <div className="space-y-3">
          {!publicKey ? (
            <WalletMultiButton className="w-full bg-[#9945FF] hover:bg-[#9945FF]/80 text-black rounded py-3 font-bold" />
          ) : (
            <>
              <button
                onClick={handleOpenPaymentGate}
                className="w-full bg-[#14F195] hover:bg-[#14F195]/80 text-black rounded py-3 font-bold"
              >
                Gain Access
              </button>
              <p className="text-center text-gray-500 text-sm">
                Secured by Solana Pay
              </p>
            </>
          )}
        </div>

        <div className="mt-12 pt-8 border-t border-white/10">
          <p className="text-sm text-gray-400">
            I built this for my own trading - a clear view into pump.fun without 
            the noise of duplicate tokens and obvious rugs. Now it's available to you.
          </p>
          <p className="text-gray-500 text-sm mt-2">
            ~ qntdev
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#0F1114] text-white">
      <Routes>
        <Route 
          path="/" 
          element={<LandingPage onOpenPaymentGate={handleOpenPaymentGate} />} 
        />
        <Route
          path="/app"
          element={
            hasPermission ? (
              <TokenScreener />
            ) : (
              renderAccessGate()
            )
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {isPaymentGateOpen && !isTreasuryWallet && (
        <PaymentGate
          onSuccess={handlePaymentSuccess}
          onClose={() => setIsPaymentGateOpen(false)}
        />
      )}
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-[#0F1114] font-[JetBrains Mono]">
        <WalletContextProvider>
          <AppContent />
        </WalletContextProvider>
      </div>
    </ErrorBoundary>
  );
}

export default App;

import { FilterSettings } from '../../utils/types';

interface FilterInputsProps {
  filters: FilterSettings;
  onFilterChange: (key: keyof FilterSettings, value: number) => void;
}

export function FilterInputs({ filters, onFilterChange }: FilterInputsProps) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Market Cap
        </label>
        <input
          type="number"
          value={filters.minMarketCap}
          onChange={(e) => onFilterChange('minMarketCap', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Max Market Cap
        </label>
        <input
          type="number"
          value={filters.maxMarketCap}
          onChange={(e) => onFilterChange('maxMarketCap', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Holders
        </label>
        <input
          type="number"
          value={filters.minHolders}
          onChange={(e) => onFilterChange('minHolders', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Volume 24h
        </label>
        <input
          type="number"
          value={filters.minVolume24h}
          onChange={(e) => onFilterChange('minVolume24h', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Max Dev Holding
        </label>
        <input
          type="number"
          value={filters.maxDevHolding}
          onChange={(e) => onFilterChange('maxDevHolding', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Trading Volume 24h
        </label>
        <input
          type="number"
          value={filters.minTradingVolume24h}
          onChange={(e) => onFilterChange('minTradingVolume24h', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Transactions 24h
        </label>
        <input
          type="number"
          value={filters.minTransactions24h}
          onChange={(e) => onFilterChange('minTransactions24h', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Max Price Volatility 24h
        </label>
        <input
          type="number"
          value={filters.maxPriceVolatility24h}
          onChange={(e) => onFilterChange('maxPriceVolatility24h', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Max Top Wallet %
        </label>
        <input
          type="number"
          value={filters.maxTopWalletConcentration}
          onChange={(e) => onFilterChange('maxTopWalletConcentration', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-400">
          Min Trades Last Hour
        </label>
        <input
          type="number"
          value={filters.minTradesLastHour}
          onChange={(e) => onFilterChange('minTradesLastHour', Number(e.target.value))}
          className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700"
        />
      </div>
    </div>
  );
} 

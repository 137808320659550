// src/lib/tokenStore.ts
import { create } from 'zustand';
import { TokenState, FilterSettings, PresetKey } from '../utils/types';
import { DEFAULT_FILTERS, FILTER_PRESETS } from '../utils/constants';
import { screenTokens } from './api';

export const useTokenStore = create<TokenState>((set) => ({
  tokens: [],
  filters: DEFAULT_FILTERS,
  activePreset: 'recommended',
  isAdmin: false,
  isLoading: false,
  error: null,

  setFilters: (filters: FilterSettings) => set({ filters }),
  
  setActivePreset: (preset: PresetKey) => set(state => ({
    activePreset: preset,
    filters: FILTER_PRESETS[preset].settings
  })),

  fetchTokens: async () => {
    set({ isLoading: true, error: null });
    try {
      const tokens = await screenTokens(DEFAULT_FILTERS);
      set({ tokens, isLoading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Unknown error',
        isLoading: false 
      });
    }
  }
}));


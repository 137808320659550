// src/lib/api.ts
import { 
  TokenData, 
  FilterSettings, 
  BitQueryTradeData,
  TradeRequest 
} from '../utils/types';
import { PROXY_ENDPOINT } from '../utils/constants';
import {
  calculateMarketCap,
  calculateVolatility,
  calculateTopWalletConcentration,
  calculateCurveProgress,
  getDevHolding,
  getTopHolders
} from '../utils';

export async function submitTrade(data: TradeRequest): Promise<Uint8Array> {
  const response = await fetch(PROXY_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
    },
    body: JSON.stringify({
      query: `
        mutation TradeToken($amount: Float!, $slippage: Float!, $tokenAddress: String!) {
          Solana {
            DEXTrades(
              where: {
                Trade: { 
                  Dex: { ProtocolName: { is: "pump" } }
                  Buy: {
                    Currency: {
                      MintAddress: { is: $tokenAddress }
                    }
                    Amount: { is: $amount }
                  }
                }
              }
            ) {
              Transaction {
                Signature
              }
            }
          }
        }
      `,
      variables: data
    }),
  });

  if (!response.ok) {
    throw new Error(`Trade failed: ${response.statusText}`);
  }

  const buffer = await response.arrayBuffer();
  return new Uint8Array(buffer);
}

export async function fetchTokenData(): Promise<TokenData[]> {
  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({
        query: `
          query GetPumpTokens {
            Solana {
              DEXTradeByTokens(
                where: {
                  Transaction: { Result: { Success: true } }
                  Trade: {
                    Dex: { ProtocolName: { is: "pump" } }
                    Currency: { Native: false }
                  }
                }
              ) {
                Trade {
                  Currency {
                    Name
                    MintAddress
                    Symbol
                  }
                  PriceInUSD
                  Amount
                  Dex {
                    ProtocolName
                  }
                }
                makers
                holders
                trades
                traded_volume
                volume_24h
                trades_24h
                trades_1h
                buys
                sells
                max_price_24h
                min_price_24h
              }
            }
          }
        `
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const trades = data.data.Solana.DEXTradeByTokens as BitQueryTradeData[];

    return trades.map((trade): TokenData => ({
      address: trade.Trade.Currency.MintAddress,
      name: trade.Trade.Currency.Name,
      symbol: trade.Trade.Currency.Symbol,
      price: trade.Trade.PriceInUSD,
      volume: trade.traded_volume || 0,
      volume24h: trade.volume_24h || 0,
      marketCap: calculateMarketCap(trade.Trade.PriceInUSD),
      holders: trade.holders,
      devHolding: 0,
      curveProgress: 0,
      comments: 0,
      topWalletConcentration: 0,
      volatility24h: calculateVolatility(trade.max_price_24h || 0, trade.min_price_24h || 0),
      trades24h: trade.trades_24h || 0,
      tradesLastHour: trade.trades_1h || 0,
      buysSellsRatio: trade.buys / (trade.sells || 1),
      timestamp: Date.now(),
      buys: trade.buys,
      sells: trade.sells
    }));
  } catch (error) {
    console.error('Error fetching token data:', error);
    return [];
  }
}

export async function fetchTokenMetadata(tokenAddress: string): Promise<any> {
  const response = await fetch(PROXY_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        query GetTokenMetadata($tokenAddress: String!) {
          Solana {
            Instructions(
              limit: { count: 10 }
              where: {
                Instruction: {
                  Program: {
                    Method: { is: "create" }
                    AccountNames: { includes: { is: "mint" } }
                  }
                  Accounts: {
                    includes: {
                      Address: { is: $tokenAddress }
                    }
                  }
                }
              }
            ) {
              Instruction {
                Program {
                  Arguments {
                    Name
                    Value {
                      ... on Solana_ABI_String_Value_Arg {
                        string
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        tokenAddress
      }
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch metadata: ${response.statusText}`);
  }

  return response.json();
}

// Helper function to get dev holding percentage
async function fetchDevHolding(tokenAddress: string): Promise<number> {
  const query = `
    query GetDevHolding($token: String) {
      Solana {
        Instructions(
          where: {
            Instruction: {
              Program: { Method: { is: "create" }, Name: { is: "pump" } }
              Accounts: { includes: { Address: { is: $token } } }
            }
          }
        ) {
          Transaction {
            Signer
          }
        }
      }
    }
  `;

  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({ 
        query,
        variables: { token: tokenAddress }
      })
    });

    const data = await response.json();
    if (!data.data?.Solana?.Instructions?.[0]?.Transaction?.Signer) {
      return 0;
    }

    const devAddress = data.data.Solana.Instructions[0].Transaction.Signer;
    
    // Get dev's current holding
    const holdingQuery = `
      query GetDevBalance($dev: String, $token: String) {
        Solana {
          BalanceUpdates(
            where: {
              BalanceUpdate: {
                Account: { Owner: { is: $dev } }
                Currency: { MintAddress: { is: $token } }
              }
            }
          ) {
            BalanceUpdate {
              balance: PostBalance(maximum: Block_Slot)
            }
          }
        }
      }
    `;

    const holdingResponse = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({ 
        query: holdingQuery,
        variables: { 
          dev: devAddress,
          token: tokenAddress 
        }
      })
    });

    const holdingData = await holdingResponse.json();
    const balance = holdingData.data?.Solana?.BalanceUpdates?.[0]?.BalanceUpdate?.balance || 0;
    
    // Calculate percentage of total supply (1 billion)
    return (balance / 1_000_000_000) * 100;
  } catch (error) {
    console.error('Error fetching dev holding:', error);
    return 0;
  }
}

// Helper function to calculate bonding curve progress
async function fetchBondingCurveProgress(tokenAddress: string): Promise<number> {
  const query = `
    query GetLatestLiquidityForPool($token: String) {
      Solana {
        DEXPools(
          where: {
            Pool: {
              Market: {
                BaseCurrency: { MintAddress: { is: $token } }
              }
              Dex: {
                ProgramAddress: { is: "6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P" }
              }
            }
          }
          orderBy: { descending: Block_Slot }
          limit: { count: 1 }
        ) {
          Pool {
            Base {
              PostAmount
            }
          }
        }
      }
    }
  `;

  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({ 
        query,
        variables: { token: tokenAddress }
      })
    });

    const data = await response.json();
    const balance = data.data?.Solana?.DEXPools?.[0]?.Pool?.Base?.PostAmount || 0;
    
    // Using the formula from docs:
    // bondingCurveProgress = 100 - (((balance - 206900000)*100)/793100000)
    const reservedTokens = 206900000;
    const initialRealTokenReserves = 793100000; // 1B - reservedTokens
    
    const progress = 100 - (((balance - reservedTokens) * 100) / initialRealTokenReserves);
    return Math.max(0, Math.min(100, progress)); // Clamp between 0-100
  } catch (error) {
    console.error('Error calculating bonding curve progress:', error);
    return 0;
  }
}

// Helper function to get top wallet concentration
async function fetchTopWalletConcentration(tokenAddress: string): Promise<number> {
  const query = `
    query GetTopHolders($token: String) {
      Solana {
        BalanceUpdates(
          limit: { count: 10 }
          orderBy: { descendingByField: "BalanceUpdate_Holding_maximum" }
          where: {
            BalanceUpdate: {
              Currency: { MintAddress: { is: $token } }
            }
            Transaction: { Result: { Success: true } }
          }
        ) {
          BalanceUpdate {
            Holding: PostBalance(maximum: Block_Slot)
          }
        }
      }
    }
  `;

  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({ 
        query,
        variables: { token: tokenAddress }
      })
    });

    const data = await response.json();
    const topHoldings = data.data?.Solana?.BalanceUpdates?.map(
      (update: any) => update.BalanceUpdate.Holding
    ) || [];

    // Calculate concentration (sum of top 10 holdings as percentage of total supply)
    const totalTopHoldings = topHoldings.reduce((sum: number, holding: number) => sum + holding, 0);
    return (totalTopHoldings / 1_000_000_000) * 100;
  } catch (error) {
    console.error('Error fetching top wallet concentration:', error);
    return 0;
  }
}

interface TokenTradeData {
  Trade: {
    Currency: {
      MintAddress: string;
      Name: string;
      Symbol: string;
    };
    PriceInUSD: number;
  };
  traded_volume: number;
  volume_24h?: number;
  holders: number;
  trades_24h?: number;
  trades_1h?: number;
  buys: number;
  sells: number;
}

// Update the main screenTokens function to use these helpers
export async function screenTokens(filters: FilterSettings): Promise<TokenData[]> {
  const query = `
    query GetPumpTokens {
      Solana {
        DEXTradeByTokens(
          where: {
            Transaction: { Result: { Success: true } }
          }
        ) {
          Trade {
            Currency {
              Name
              Symbol
              MintAddress
            }
            Price
            PriceInUSD
          }
          makers: count(distinct: Transaction_Signer)
          holders: count
          trades: count
          traded_volume: sum(of: Trade_Side_AmountInUSD)
          volume_24h: sum(
            of: Trade_Side_AmountInUSD
            if: { Block: { Time: { since: "${new Date(Date.now() - 24*60*60*1000).toISOString()}" } } }
          )
          trades_24h: count(
            if: { Block: { Time: { since: "${new Date(Date.now() - 24*60*60*1000).toISOString()}" } } }
          )
          trades_1h: count(
            if: { Block: { Time: { since: "${new Date(Date.now() - 60*60*1000).toISOString()}" } } }
          )
          buys: count(if: { Trade: { Side: { Type: { is: "buy" } } } })
          sells: count(if: { Trade: { Side: { Type: { is: "sell" } } } })
        }
      }
    }
  `;

  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': import.meta.env.VITE_BITQUERY_API_KEY
      },
      body: JSON.stringify({ query })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    
    if (data.errors) {
      throw new Error(data.errors[0].message);
    }

    const trades = data.data.Solana.DEXTradeByTokens as BitQueryTradeData[];
    
    return trades
      .map((trade): TokenData => ({
        address: trade.Trade.Currency.MintAddress,
        name: trade.Trade.Currency.Name,
        symbol: trade.Trade.Currency.Symbol,
        price: trade.Trade.PriceInUSD,
        volume: trade.traded_volume || 0,
        volume24h: trade.volume_24h || 0,
        marketCap: trade.Trade.PriceInUSD * 1_000_000_000,
        holders: trade.holders || 0,
        devHolding: 0, // Will be fetched separately
        curveProgress: 0, // Will be calculated
        comments: 0,
        topWalletConcentration: 0,
        volatility24h: 0,
        trades24h: trade.trades_24h || 0,
        tradesLastHour: trade.trades_1h || 0,
        buysSellsRatio: trade.buys / (trade.sells || 1),
        timestamp: Date.now(),
        buys: trade.buys || 0,
        sells: trade.sells || 0
      }))
      .filter(token => (
        token.marketCap >= filters.minMarketCap &&
        token.marketCap <= filters.maxMarketCap &&
        token.holders >= filters.minHolders &&
        token.volume24h >= filters.minVolume24h &&
        token.devHolding <= filters.maxDevHolding
      ));

  } catch (error) {
    console.error('Error fetching tokens:', error);
    throw error;
  }
}

import { useCallback } from 'react';
import { useTokenStore } from '../lib/tokenStore';
import { FilterSettings, PresetKey } from '../utils/types';
import { FILTER_PRESETS } from '../utils/constants';

export function useTokenScreener() {
  const store = useTokenStore();

  const handleFilterChange = useCallback((key: keyof FilterSettings, value: number) => {
    store.setFilters({ 
      ...store.filters, 
      [key]: value 
    });
  }, [store.filters, store.setFilters]);

  const handlePresetChange = useCallback((preset: PresetKey) => {
    store.setActivePreset(preset);
    store.setFilters(FILTER_PRESETS[preset].settings);
  }, [store.setActivePreset, store.setFilters]);

  return {
    ...store,
    handleFilterChange,
    handlePresetChange,
  };
} 

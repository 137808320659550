/// <reference types="vite/client" />

// Network and Cluster Configuration
export const NETWORK = import.meta.env.VITE_NETWORK || 'mainnet-beta';
export const CLUSTER = import.meta.env.VITE_CLUSTER || 'mainnet-beta';

// API Endpoints
export const HELIUS_RPC_URL = `https://rpc.helius.xyz/?api-key=${import.meta.env.VITE_RPC_ENDPOINT}`;
export const WEBSOCKET_URL = import.meta.env.VITE_WS_URL;

// Wallet Configuration
export const TREASURY_WALLET = import.meta.env.VITE_TREASURY_WALLET;

// API Keys
export const BITQUERY_API_KEY = import.meta.env.VITE_BITQUERY_API_KEY;

// Single source of truth for environment variables
export const CONSTANTS = {
  RPC_ENDPOINT: import.meta.env.VITE_RPC_ENDPOINT,
  WS_URL: import.meta.env.VITE_WS_URL,
  TREASURY_WALLET: import.meta.env.VITE_TREASURY_WALLET,
  BITQUERY_API_KEY: import.meta.env.VITE_BITQUERY_API_KEY
} as const;

// src/components/TokenScreener/index.tsx
import { useEffect } from 'react';
import { useTokenScreener } from '../../hooks/useTokenScreener';
import { FilterInputs } from './FilterInputs';
import { TokenTable } from './TokenTable';
import { FILTER_PRESETS } from '../../utils/constants';
import { PresetKey } from '../../utils/types';

export function TokenScreener() {
  const { 
    tokens, 
    filters,
    activePreset,
    isLoading, 
    error,
    handleFilterChange,
    handlePresetChange,
    fetchTokens 
  } = useTokenScreener();

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens, filters]);

  return (
    <div className="min-h-screen bg-[#0F1114] p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">// quant</h1>
        <div className="flex gap-4">
          {Object.entries(FILTER_PRESETS).map(([key, { label }]) => (
            <button
              key={key}
              onClick={() => handlePresetChange(key as PresetKey)}
              className={`px-4 py-2 rounded-lg font-medium transition-colors
                ${activePreset === key 
                  ? 'bg-[#14F195] text-black' 
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'}`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      <FilterInputs 
        filters={filters}
        onFilterChange={handleFilterChange}
      />

      {error && (
        <div className="text-red-400 text-center py-4">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#14F195] mx-auto"></div>
        </div>
      ) : (
        <div className="mt-6">
          <TokenTable tokens={tokens} />
        </div>
      )}
    </div>
  );
}







import create from 'zustand';
import { persist } from 'zustand/middleware';
import { CONSTANTS } from './constants';

interface PaymentStore {
  hasAccess: boolean;
  signature: string | null;
  setAccess: (signature: string) => void;
  verifyPayment: (signature: string) => Promise<boolean>;
  checkTreasuryAccess: (publicKey: string) => boolean;
  setTreasuryAccess: () => void;
}

export const usePaymentStore = create<PaymentStore>()(
  persist(
    (set) => ({
      hasAccess: false,
      signature: null,
      setAccess: (signature) => set({ hasAccess: true, signature }),
      setTreasuryAccess: () => set({ hasAccess: true, signature: 'treasury' }),
      verifyPayment: async (signature) => {
        try {
          // Add actual verification logic here if needed
          return true;
        } catch (error) {
          console.error('Payment verification failed:', error);
          return false;
        }
      },
      checkTreasuryAccess: (publicKey) => publicKey === CONSTANTS.TREASURY_WALLET
    }),
    {
      name: 'payment-store'
    }
  )
);





import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { Buffer } from 'buffer';

// Add these imports at the top
import { Filter /* other icons */ } from 'lucide-react';

// Add this before your app code
declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}
window.Buffer = Buffer;

// Validate required environment variables
const requiredEnvVars = {
  'VITE_RPC_ENDPOINT': import.meta.env.VITE_RPC_ENDPOINT,
  'VITE_TREASURY_WALLET': import.meta.env.VITE_TREASURY_WALLET,
  'VITE_BITQUERY_API_KEY': import.meta.env.VITE_BITQUERY_API_KEY,
  'VITE_WS_URL': import.meta.env.VITE_WS_URL,
};

const missingEnvVars = Object.entries(requiredEnvVars)
  .filter(([_, value]) => !value)
  .map(([key]) => key);

if (missingEnvVars.length > 0) {
  console.error('Missing required environment variables:', missingEnvVars);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

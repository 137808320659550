import { TokenData } from '../../types';
import { formatNumber, formatCompactNumber, formatPercentage } from '../../utils/formatters';

interface TokenTableProps {
  tokens: TokenData[];
}

export const TokenTable = ({ tokens }: { tokens: TokenData[] }) => {
  if (!tokens?.length) {
    return <div>No tokens found</div>;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Price</th>
          <th>Market Cap</th>
          {/* Add other columns */}
        </tr>
      </thead>
      <tbody>
        {tokens.map(token => (
          <tr key={token.address}>
            <td>{token.symbol}</td>
            <td>${token.price.toFixed(6)}</td>
            <td>${formatCompactNumber(token.marketCap)}</td>
            {/* Add other cells */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

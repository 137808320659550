// src/components/PaymentGate.tsx
import { FC, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { motion } from 'framer-motion';
import { usePaymentStore } from '../lib/payment';
import { PublicKey, SystemProgram, LAMPORTS_PER_SOL, Transaction, Connection } from '@solana/web3.js';

interface PaymentGateProps {
  onSuccess: () => void;
  onClose: () => void;
}

export const PaymentGate: FC<PaymentGateProps> = ({ onSuccess, onClose }) => {
  const { publicKey, sendTransaction } = useWallet();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setAccess, verifyPayment } = usePaymentStore();

  const apiKey = import.meta.env.VITE_RPC_ENDPOINT || '';
  const endpoint = `https://mainnet.helius-rpc.com/?api-key=${apiKey}`;
  const connection = new Connection(endpoint, { 
    commitment: 'confirmed', 
    confirmTransactionInitialTimeout: 60000 
  });

  const handlePayment = async () => {
    if (!publicKey || !sendTransaction) {
      setError('Wallet not connected');
      return;
    }

    setError(null);
    setIsProcessing(true);

    try {
      const TREASURY_WALLET = "FrNLPieHJCQ4ZU76BTcKVyHkxVuKr92PWEj3E4sWoVJb";
      const paymentAmount = 5 * LAMPORTS_PER_SOL;

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(TREASURY_WALLET),
          lamports: paymentAmount,
        })
      );

      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash('confirmed');
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;

      console.log('Sending transaction...');
      const signature = await sendTransaction(transaction, connection);
      console.log('Transaction sent:', signature);

      console.log('Confirming transaction...');
      const confirmation = await connection.confirmTransaction(
        { blockhash, lastValidBlockHeight, signature },
        'confirmed'
      );

      if (confirmation.value.err) {
        throw new Error('Transaction failed');
      }

      console.log('Verifying payment...');
      const verified = await verifyPayment(signature);
      
      if (verified) {
        console.log('Payment verified');
        setAccess(signature);
        onSuccess();
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setError(error instanceof Error ? error.message : 'Payment failed');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="relative w-full max-w-md">
        <button 
          onClick={onClose}
          className="absolute -top-2 -right-2 w-8 h-8 flex items-center justify-center rounded-full bg-white/10 text-white hover:bg-white/20 transition-colors"
        >
          ×
        </button>
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="backdrop-blur-sm bg-white/5 rounded-lg p-8 border border-white/10"
        >
          <h2 className="text-2xl font-mono mb-4 text-white">access required</h2>
          <p className="text-gray-400 mb-6">
            5 sol payment required to access the app
          </p>
          
          {error && (
            <p className="text-red-400 mb-4 text-sm">{error}</p>
          )}

          <div className="space-y-3">
            <button
              onClick={handlePayment}
              disabled={isProcessing || !publicKey}
              className="w-full bg-[#14F195] hover:bg-[#14F195]/80 disabled:bg-[#14F195]/50 text-black font-bold py-3 px-4 rounded-lg border border-transparent transition-all disabled:cursor-not-allowed"
            >
              {isProcessing ? (
                <span className="flex items-center justify-center">
                  <span className="inline-block animate-spin mr-2">⟳</span>
                  Processing Transaction...
                </span>
              ) : (
                'limited to 17 more people'
              )}
            </button>
            
            <p className="text-center text-gray-500 text-sm">
              Secured by Solana Pay
            </p>
          </div>

          <div className="mt-8 pt-6 border-t border-white/10">
            <p className="text-sm text-gray-400 italic">
              "I built this for my own trading - a clear view into pump.fun without 
              the noise of duplicate tokens and obvious rugs. Now it's available to you."
            </p>
            <p className="text-gray-500 text-sm mt-2">
              ~ qntdev
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};


import { FilterSettings, PresetKey } from './types';

export const PROXY_ENDPOINT = 'https://graphql.bitquery.io';

export const DEFAULT_FILTERS: FilterSettings = {
  minMarketCap: 10000,
  maxMarketCap: 1000000,
  minHolders: 100,
  minVolume24h: 5000,
  maxDevHolding: 20,
  minTradingVolume24h: 5000,
  minTransactions24h: 50,
  maxPriceVolatility24h: 30,
  maxTopWalletConcentration: 5,
  minTradesLastHour: 10
};

export const FILTER_PRESETS: Record<PresetKey, { label: string; settings: FilterSettings }> = {
  recommended: {
    label: 'Recommended',
    settings: {
      minMarketCap: 10000,
      maxMarketCap: 1000000,
      minHolders: 100,
      minVolume24h: 5000,
      maxDevHolding: 20,
      minTradingVolume24h: 5000,
      minTransactions24h: 50,
      maxPriceVolatility24h: 30,
      maxTopWalletConcentration: 5,
      minTradesLastHour: 10
    }
  },
  momentum: {
    label: 'High Momentum',
    settings: {
      minMarketCap: 50000,
      maxMarketCap: 5000000,
      minHolders: 200,
      minVolume24h: 10000,
      maxDevHolding: 15,
      minTradingVolume24h: 10000,
      minTransactions24h: 100,
      maxPriceVolatility24h: 50,
      maxTopWalletConcentration: 3,
      minTradesLastHour: 20
    }
  },
  early: {
    label: 'Early Detection',
    settings: {
      minMarketCap: 5000,
      maxMarketCap: 500000,
      minHolders: 50,
      minVolume24h: 2000,
      maxDevHolding: 25,
      minTradingVolume24h: 2000,
      minTransactions24h: 20,
      maxPriceVolatility24h: 40,
      maxTopWalletConcentration: 8,
      minTradesLastHour: 5
    }
  }
};

// src/components/LandingPage.tsx
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { RoadmapItem } from '../lib/types';
import { usePaymentStore } from '../lib/payment';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import '@solana/wallet-adapter-react-ui/styles.css';
import { CONSTANTS } from '../lib/constants';

interface LandingPageProps {
  onOpenPaymentGate: () => void;
}

function Roadmap({ items }: { items: RoadmapItem[] }) {
  return (
    <div className="grid gap-6">
      {items.map((item, index) => (
        <motion.div
          key={item.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="backdrop-blur-sm bg-white/5 rounded-lg p-6 border border-white/10"
        >
          <div className="flex justify-between items-start mb-2">
            <h3 className="text-lg font-medium">{item.title}</h3>
            <span className={`px-2 py-1 rounded-full text-xs ${
              item.status === 'completed' ? 'bg-[#14F195]/10 text-[#14F195]' :
              item.status === 'upcoming' ? 'bg-[#9945FF]/10 text-[#9945FF]' :
              'bg-gray-400/10 text-gray-400'
            }`}>
              {item.status}
            </span>
          </div>
          <p className="text-gray-400">{item.description}</p>
        </motion.div>
      ))}
    </div>
  );
}

export function LandingPage({ onOpenPaymentGate }: LandingPageProps) {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const { hasAccess, setTreasuryAccess } = usePaymentStore();
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (publicKey?.toBase58() === CONSTANTS.TREASURY_WALLET) {
      setTreasuryAccess();
    }
  }, [publicKey, setTreasuryAccess]);

  const handleEnterApp = async () => {
    if (!publicKey) {
      setIsConnecting(true);
      return;
    }

    try {
      const isTreasuryWallet = publicKey.toBase58() === CONSTANTS.TREASURY_WALLET;
      if (hasAccess || isTreasuryWallet) {
        navigate('/app');
      } else {
        onOpenPaymentGate();
      }
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const roadmapItems: RoadmapItem[] = [
    {
      title: 'Real-time Token Tracking',
      description: 'Live monitoring of Pump.fun token trades and metrics',
      status: 'completed'
    },
    {
      title: 'Advanced Filtering',
      description: 'Customizable filters for token discovery',
      status: 'completed'
    },
    {
      title: 'Trading Integration',
      description: 'Direct trading capabilities from the dashboard',
      status: 'upcoming'
    },
    {
      title: 'Portfolio Analytics',
      description: 'Track your token holdings and performance',
      status: 'upcoming'
    },
    {
      title: 'Social Signals',
      description: 'Integration with social media metrics and sentiment',
      status: 'planned'
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white font-mono">
      {/* Header */}
      <div className="fixed top-0 z-50 w-full backdrop-blur-sm bg-black/50 border-b border-white/10">
        <div className="max-w-5xl mx-auto px-6 py-4 flex justify-between items-center">
          <div className="text-xl">// quant</div>
          <div className="flex items-center space-x-4">
            <WalletMultiButton className="px-4 py-2 bg-[#9945FF] hover:bg-[#9945FF]/80 text-black rounded" />
            <button
              onClick={handleEnterApp}
              className="px-4 py-2 bg-[#14F195] hover:bg-[#14F195]/80 text-black rounded"
              disabled={isConnecting}
            >
              {isConnecting ? 'Connecting...' : publicKey ? 'Launch App' : 'Connect Wallet'}
            </button>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="max-w-5xl mx-auto px-6 pt-32 pb-20">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
          <h1 className="text-5xl mb-6 leading-tight">a new ui for pump.fun</h1>
          <p className="text-gray-400 mb-8 text-xl">
            plus alternative data for memecoin traders, signal providers, and developers
          </p>
          <button
            onClick={handleEnterApp}
            className="px-6 py-3 bg-[#14F195] hover:bg-[#14F195]/80 text-black rounded text-lg"
            disabled={isConnecting}
          >
            {isConnecting ? 'connecting wallet...' : 'launch app'}
          </button>
        </motion.div>
      </div>

      {/* Founder's Letter */}
      <div className="max-w-5xl mx-auto px-6 py-20 border-t border-white/10">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="max-w-3xl">
          <h2 className="text-3xl mb-12">A Letter from the Founder</h2>
          <div className="space-y-8 text-lg leading-relaxed text-gray-300">
            <p>Today, the token landscape is a mess. Thousands of launches daily, most destined to fail. Fake engagement. Copy-paste projects. 'Developers' with no track record. It's a minefield where your time and capital are at constant risk.</p>
            <p>Add up all the losses from aping into the wrong projects this past year. You could've bought a lambo truck by now.</p>
            <p>Ever notice how you see the best plays only after KOLs start shilling? By then, smart money is already in. But why wait for their signal when you could spot winners before the crowd?</p>
            <p className="text-[#14F195] font-bold text-xl">The noise ends here.</p>
            <p>We've built what serious traders have been asking for: a professional-grade token screener that actually works. What Tensor did for NFTs, we've built for meme coins and we're just getting started.</p>
            <p className="text-xl font-bold">It's time to own your edge instead of renting it.</p>
            <div className="mt-16 space-y-4">
              <p className="text-gray-400">Thank you for being here,</p>
              <p className="font-mono text-[#14F195] text-xl tracking-wider">~ qntdev</p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Roadmap Section */}
      <div className="max-w-5xl mx-auto px-6 py-20 border-t border-white/10">
        <h2 className="text-3xl mb-12">roadmap</h2>
        <Roadmap items={roadmapItems} />
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-16 text-center"
        >
          <button
            onClick={handleEnterApp}
            className="px-8 py-4 bg-[#14F195] hover:bg-[#14F195]/80 text-black rounded text-lg"
            disabled={isConnecting}
          >
            {isConnecting ? 'connecting wallet...' : 'launch app'}
          </button>
        </motion.div>
      </div>
    </div>
  );
}
